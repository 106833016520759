<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      title="编辑"
      :ok-button-props="{ props: { loading: submitting } }"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="短信接口名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入短信接口名称' },
                { max: 50, message: '最多50个字符' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="运营商种类">
          <a-select
            v-decorator="['carrier_type', {
              initialValue: 'china_mobile',
              rules: [
                { required: true, message: '请选择运营商种类' },
                { validator: verifyCarrierType }
              ]
            }]"
            allow-clear
          >
            <a-select-option
              v-for="option in carrierTypeOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="接口种类">
          <a-select
            v-decorator="['sms_channel_type', {
              initialValue: 'database',
              rules: [{ required: true, message: '请选择接口种类' }
              ]
            }]"
            allow-clear
          >
            <a-select-option
              v-for="option in smsChannelTypeOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="短信接口配置">
          <a-textarea
            v-decorator="['sms_setting', {
              rules: [
                { required: true, message: '请输入短信接口配置' },
                { max: 200, message: '最多200个字符' },
              ]
            }]"
            placeholder="输入形式key:value，输入内容:ip,port,user_name，password,applicationid,extcode"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findCarrierTypeOptions, findSmsChannelTypeOptions } from '@/api/common_options'
import { findSmsChannelForm, updateSmsChannel } from '@/api/sms_channel'
export default {
  name: 'EditSmsChannel',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'new_sms_channel' }),
      submitting: false,
      carrierTypeOptions: findCarrierTypeOptions(),
      smsChannelTypeOptions: findSmsChannelTypeOptions(),
      currentCarrierType: '',
      carriersCount: 0
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    verifyCarrierType(rule, value, callback) {
      if (value && this.carriersCount > 0 && value !== this.currentCarrierType) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('已有运营商账户使用此接口，无法修改此参数')
      } else {
        callback()
      }
    },

    fetchFormData() {
      findSmsChannelForm(this.id).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue({
            name: res.data.name,
            carrier_type: res.data.carrier_type,
            sms_channel_type: res.data.sms_channel_type,
            sms_setting: res.data.sms_setting
          })
          this.currentCarrierType = res.data.carrier_type
          this.carriersCount = res.data.carriers_count
        }
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          updateSmsChannel(this.id, values).then((res) => {
            if (res.code === 0) {
              this.isShow = false
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
